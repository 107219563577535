@import '../../../../scss/theme-bootstrap';

// new for 2018 Creative Refresh

$product-full-thumb-size: 40px;

.product-full__image {
  text-align: center;
  .product-full__carousel {
    position: relative;
    font-size: 0;
    transition: display 0.3s;
    top: 0;
    min-height: 350px;
    @include breakpoint($bp--medium-up) {
      min-height: 0;
    }
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      width: 95%;
      float: $ldirection;
      margin: 0;
    }
    &-slides {
      margin-bottom: 0;
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        width: auto;
        margin-top: 10px;
      }
      &.slick-initialized {
        .product-full__carousel-slide {
          display: block;
          .product-full__carousel-slide-img {
            display: block;
          }
        }
      }
    }
    &-slide {
      display: none;
      width: 100%;
      margin: 0 auto;
      @include breakpoint($bp--medium-up) {
        max-height: none;
      }
      &:first-child {
        display: block;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .zoomImg {
        cursor: zoom-in;
      }
    }
    &-slide-img {
      width: 100%;
      display: none;
      @include breakpoint($bp--medium-up) {
        width: auto;
        margin: 0 auto;
        max-height: 500px;
      }
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        max-height: none;
        width: 100%;
      }
      &:first-child {
        display: block;
      }
    }
    &-thumbs {
      position: relative;
      display: none;
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        margin: 0 12px 0 0;
        display: block;
        width: $product-full-thumb-size;
        vertical-align: top;
        z-index: 10;
        float: $ldirection;
        top: 0;
        #{$ldirection}: 0;
        &.sticky {
          position: fixed;
          top: 150px;
          margin-top: -2px;
          @include breakpoint($bp--xlarger-up) {
            height: 290px;
          }
        }
        &.stuck {
          position: absolute;
        }
      }
      &-container {
        max-height: 260px;
        overflow-y: hidden;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
      }
      &-arrow--down {
        @include icon(arrow--down);
        &::before {
          font-size: 15px;
          float: $ldirection;
          width: 100%;
          height: 15px;
          font-weight: 700;
          cursor: pointer;
        }
      }
      &-arrow--up {
        @include icon(arrow--up);
        &::before {
          position: absolute;
          top: -25px;
          #{$ldirection}: 0;
          margin-bottom: 12px;
          font-size: 15px;
          float: #{$ldirection};
          width: 100%;
          height: 15px;
          font-weight: 700;
          cursor: pointer;
          @include breakpoint($bp--xlarger-up) {
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }
    &-thumb {
      cursor: pointer;
      display: block;
      overflow: hidden;
      margin-bottom: 12px;
      max-width: 100%;
      max-height: $product-full-thumb-size;
      &.active {
        border-bottom: 2px solid $color--black;
        //opacity: 1;
      }
      &-image {
        display: block;
        height: $product-full-thumb-size;
        width: auto;
      }
    }
  }
  .carousel-dots {
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      display: none;
    }
    .slick-dots {
      bottom: -35px;
    }
  }
  .carousel--has-dots .slick-dots > li button {
    width: 2px;
    height: 2px;
    padding: 2px;
    background-color: $color--gray--light;
    border-color: $color--gray--light;
    margin: 0 auto;
  }
  .carousel--has-dots .slick-dots > li.slick-active button,
  .carousel--has-dots .slick-dots > li:hover button {
    background-color: $color--black;
    border-color: $color--black;
  }
}

.product-full__image-zoom {
  position: relative;
  top: 10px;
  z-index: 1;
  padding-#{$ldirection}: 24px;
  width: 50px;
  height: 23px;
  border-bottom: 0;
  @include breakpoint(($bp--large-up) (orientation landscape)) {
    display: none;
  }
  i {
    float: #{$ldirection};
    cursor: pointer;
  }
}

.img-Zoom-Modal {
  position: fixed; /* Stay in place */
  z-index: 99999999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: $color--white; /* Fallback color */
  img {
    width: 150%;
    max-width: none;
    position: absolute;
    height: auto;
    margin: auto;
    @include breakpoint($bp--small-down) {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .icon--remove {
    position: fixed;
    right: 30px;
    top: 18px;
    width: 12px;
    height: 12px;
  }
}

.product-full--enhanced {
  .product-full__image {
    .product-full__carousel {
      min-height: 300px;
      &-thumbs {
        display: block;
        position: absolute;
        z-index: 10;
        #{$ldirection}: 20px;
        top: 50%;
        margin: 0;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        @include breakpoint(($bp--large-up) (orientation landscape)) {
          position: relative;
          float: $ldirection;
          top: 20px;
          #{$ldirection}: 0;
          transform: none;
          -webkit-transform: none;
          -ms-transform: none;
        }
        &-arrow--up,
        &-arrow--down {
          height: 14px;
          width: 30px;
          &:before {
            content: '';
            #{$ldirection}: 30%;
          }
        }
        &-arrow--up {
          margin-bottom: 5px;
          &:before {
            @include arrow-pointer($color: $color--black, $direction: up);
            top: 40%;
            position: relative;
          }
        }
        &-arrow--down {
          &:before {
            @include arrow-pointer($color: $color--black, $direction: down);
            position: relative;
          }
        }
      }
      &-slides {
        .product-full__carousel-slide-img {
          @include breakpoint($bp--medium-down) {
            border-top: 1px solid $color--gray--lighter;
            border-bottom: 1px solid $color--gray--lighter;
          }
        }
        .slick-prev,
        .slick-next {
          width: 8%;
          font-size: 13px;
          height: 33px;
          &:active:before,
          &:hover:before {
            color: $color--white;
          }
          &.slick-disabled {
            visibility: hidden;
          }
        }
        .slick-prev {
          display: none;
        }
        .slick-next {
          @include breakpoint($bp--medium-down) {
            z-index: 1;
          }
          height: 30px;
          width: 30px;
          background: $color--black;
          #{$rdirection}: 0;
          &:before {
            @include arrow-pointer($color: $color--white, $direction: $rdirection);
            content: '';
            #{$rdirection}: 40%;
            padding: 0;
          }
        }
      }
    }
    .carousel-dots {
      position: absolute;
      width: 35%;
      bottom: 40px;
      #{$rdirection}: 0;
    }
    .slick-dots {
      li {
        width: 0;
      }
    }
    .carousel--has-dots .slick-dots > li button {
      color: transparent;
      background-color: transparent;
    }
    .carousel--has-dots .slick-dots > li.slick-active button,
    .carousel--has-dots .slick-dots > li:hover button {
      background-color: $color--gray--light;
      border-color: transparent;
    }
  }
}

// specificity wars
// normalize display for responsive testing even tho we hide these for pc
.device-pc .product-full__carousel.carousel--has-dots .slick-dots {
  width: 100%;
}

// SPP Alt video youtube styles.
.video-play-icon {
  background: url('#{$base-theme-path}svg-icons/src/icon--play-dark.svg') no-repeat;
  width: 60px;
  height: 60px;
  position: absolute;
  #{$ldirection}: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-size: 100%;
}

.video-close-icon {
  background: url('#{$base-theme-path}svg-icons/src/icon--cross-dark.svg') no-repeat;
  width: 48px;
  height: 48px;
  z-index: 3;
  position: absolute;
  #{$rdirection}: 5px;
  top: 5px;
  background-size: 100%;
}

.product-full__alt-video-poster-wrapper {
  position: relative;
  cursor: pointer;
}

.product-full__images {
  &.alt-video-playing {
    .product-full__badge,
    .carousel-dots {
      display: none;
    }
    .alt-video-wrapper {
      background-color: $color--black;
    }
  }
}